import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import ReplicateMeCell from "../component/ReplicateMeCell";
import ReplicateAICell from "../component/ReplicateAICell";
import ScribbleTabBar from "../component/ScribbleTabBar";
import ReplicateInfoHeader from "../component/ReplicateInfoHeader";
import {imageToImage, haveUsedMiniApp, getChatStr, getScribble, getReplicatePic, uploadReplicatePic} from "../../utils/service";
import useParsedQueryString from "../../utils/useParsedQueryString";
import {Button} from 'antd-mobile'
import Icon_pic from "../../asset/icon_pic@2x.png";
import Icon_pic_light from "../../asset/icon_pic_light.png";

import lrz from "lrz"
import {getLanguageStr} from "../../utils/utils";

function Scribble() {

    const location = useParsedQueryString()
    const [data, setData] = useState([])

    const [newBlob, setNewBlob] = useState(undefined)

    const [pic, setPic] = useState(undefined)
    const [canSend, setCanSend] = useState(false)

    const [theme, setTheme] = useState("dark")
    const [lang, setLang] = useState("en")
    const [timer, setTimer] = useState(null);
    const [replicateId, setReplicateId] = useState(null)

    useEffect(() => {

        document.title = 'Generate detailed images from scribbled drawings'
        // 设置token
        localStorage.setItem("token", location.token)
        setTheme(location.theme)
        setLang(location.lang)

        let el = document.getElementById("body_theme")
        if (location.theme === "light") {
            el.style.backgroundColor = "#FFFFFF"
        } else {
            el.style.backgroundColor = "#000000"
        }
    }, [location])

    function userMiniAPP() {
        localStorage.setItem("token", location.token)
        haveUsedMiniApp({miniId: "g0dbecb72c84987512a"}).then((response) => {
            if (response.data.code === 200) {
                console.log("积分消耗成功")
            } else {
                console.log("积分消耗失败")
            }
        })

    }

    function dealError(item, newData) {
        let newPics = [].concat([])

        for (let index = 0; index < item.samples; index++) {
            newPics.push("error")
        }

        newData[newData.length - 1].pics = newPics

        let newData2 = [].concat(newData);
        console.log("newData2:", newData2)
        setData(newData2)
        setCanSend(true)
    }


    const getNewPic = async (item) => {
        setCanSend(false)
        setTimeout(() => {
            let elem = document.getElementById("contentID")
            elem.scrollIntoView({behavior: "smooth", block: "end"})
        }, 300)
        let newData = data.concat([{
            from: "left",
            text: pic,
            pics: []
        }, {
            from: "right",
            pics: []
        }])
        setData(newData);
        getChatStr(
          {
              model: "gpt-3.5-turbo",
              messages: [{"role": "user", "content": "请将冒号后面的内容翻译成英文并且只返回翻译的内容:" + item.text}]
          }
        ).then((response) => {
            let choices = response.data.choices
            if (choices.length > 0) {
                let choice = choices[0]
                item.tran = choice.message.content
                const postData = {
                    image: pic,
                    prompt: choice.message.content,
                    num_samples: item.numSample,
                    image_resolution: item.imageResolution,
                    scale: item.scale
                }
                getScribble(postData).then(res => {
                    if (res.data.code === 200) {
                        if (res.data.data.status === 'starting') {
                            setReplicateId(res.data.data.id);
                            const timeInterval = setInterval(async () => {
                                const result = await getReplicatePic(res.data.data.id);
                                if (result.data.code === 200) {
                                    if (result.data.data.status === 'processing') {
                                        newData[newData.length - 1].text = 'processing'
                                        newData[newData.length - 1].logs = result.data.data.logs
                                        let newData2 = [].concat(newData);
                                        setData(newData2)
                                    }
                                    if (result.data.data.status === 'failed') {
                                        newData[newData.length - 1].text = 'failed'
                                        newData[newData.length - 1].logs = result.data.data.logs
                                        let newData2 = [].concat(newData);
                                        setData(newData2)
                                        clearInterval(timeInterval);
                                        setTimeout(() => {
                                            let elem = document.getElementById("contentID")
                                            elem.scrollIntoView({behavior: "smooth", block: "end"})
                                        }, 300)
                                    }
                                    if (result.data.data.status === 'succeeded') {
                                        newData[newData.length - 1].pics = result.data.data.output
                                        newData[newData.length - 1].logs = result.data.data.logs
                                        let newData2 = [].concat(newData);
                                        setData(newData2)
                                        setCanSend(true)
                                        clearInterval(timeInterval);
                                        userMiniAPP();
                                        setTimeout(() => {
                                            let elem = document.getElementById("contentID")
                                            elem.scrollIntoView({behavior: "smooth", block: "end"})
                                        }, 300)
                                    }
                                }
                            }, 3000)
                            setTimer(timeInterval)
                        }
                    }
                })
            }
        })
    }

    return <>
        <ScribbleTabBar fromImage={true} canSend={canSend} theme={theme} lang={lang} submit={getNewPic}/>
        <ReplicateInfoHeader lang={lang} submit={async (file, blob) => {
            const formData = new FormData();
            formData.append('pic', file);
            // let newData = data.concat([{
            //     from: "left",
            //     text: blob,
            //     pics: []
            // }, {
            //     from: "right",
            //     pics: []
            // }])
            // setData(newData);
            const res = await uploadReplicatePic(formData);
            if (res.data.code === 200) {
                setPic(res.data.data)
                setCanSend(true)
                // getNewPic(res.data.data, newData)
            }
        }}/>
        <Content lang={lang} id={"contentID"}>
            {
                data.map((item, index) => {
                    if (item.from === "left") {
                        return <ReplicateMeCell theme={theme} lang={lang} key={index} text={item.text}/>
                    } else {
                        return <ReplicateAICell theme={theme} lang={lang} key={index} pics={item.pics} text={item.text} logs={item.logs}/>
                    }
                })
            }
        </Content>
    </>
}

export default Scribble

const Content = styled.div`
  padding-top: 101px;
  padding-bottom:calc(env(safe-area-inset-bottom) + 50px) !important;
`

const Content2 = styled.div`
  height:calc(100vh - 250px);
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
 width: 80px;
 height: 80px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  text-align: center;
  width: 280px;
  margin-top:10px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

