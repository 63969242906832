import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Icon_unSend from '../../asset/icon_unsend@2x.png'
import Icon_close from '../../asset/icon_close@2x.png'
import Icon_tip_bg from '../../asset/icon_tip_bg@2x.png'
import Icon_tip_close from '../../asset/icon_tip_close@2x.png'
import Icon_send from '../../asset/icon_send@2x.png'
import Icon_config from '../../asset/icon_config@2x.png'
import Icon_config_light from '../../asset/icon_config@2x_light.png'
import Slider from 'rc-slider';
import './slider.css';
import {getLanguageStr} from "../../utils/utils";

function TabBar({canSend, submit, fromImage,theme,lang}) {

    const [showKeyboard, setShowKeyboard] = useState(false)
    const [showTip, setShowTip] = useState(true)
    const [showConfig, setShowConfig] = useState(false)


    const [text, setText] = useState("")
    const [samples, setSamples] = useState(3)
    const [style, setStyle] = useState("enhance")
    const [height, setHeight] = useState(512)
    const [width, setWidth] = useState(512)
    const [imageStrength, setImageStrength] = useState(0.35)
    const [imageMode, setImageMode] = useState("IMAGE_STRENGTH")


    const [imageModes, setImageModes] = useState([
        {
            title: "IMAGE STRENGTH",
            value: "IMAGE_STRENGTH",
            select: true
        },
        {
            title: "STEP SCHEDULE",
            value: "STEP_SCHEDULE",
            select: false
        }
    ])

    const [styles, setStyles] = useState([
        {
            title: "Enhance",
            value: "enhance",
            select: true
        },
        {
            title: "3D Model",
            value: "3d-model",
            select: false
        },
        {
            title: "Analog Film",
            value: "analog-film",
            select: false
        },
        {
            title: "Anime",
            value: "anime",
            select: false
        },
        {
            title: "Cinematic",
            value: "cinematic",
            select: false
        },
        {
            title: "Comic Book",
            value: "comic-book",
            select: false
        },
        {
            title: "Digital Art",
            value: "digital-art",
            select: false
        },
        {
            title: "Fantasy Art",
            value: "fantasy-art",
            select: false
        },
        {
            title: "Isometric",
            value: "isometric",
            select: false
        },
        {
            title: "Line Art",
            value: "line-art",
            select: false
        },
        {
            title: "Low Poly",
            value: "low-poly",
            select: false
        },
        {
            title: "Modeling Compound",
            value: "modeling-compound",
            select: false
        },
        {
            title: "Neon Punk",
            value: "neon-punk",
            select: false
        },
        {
            title: "Origami",
            value: "origami",
            select: false
        },
        {
            title: "Photographic",
            value: "photographic",
            select: false
        },
        {
            title: "Pixel Art",
            value: "pixel-art",
            select: false
        },
        {
            title: "Tile Texture",
            value: "tile-texture",
            select: false
        }
    ])

    const [quantity, setQuantity] = useState([])

    useEffect(() => {
        document.title = 'Image Generated Images'

        // 1. Android系统
        const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
        window.addEventListener('resize', () => {
            //键盘弹起与隐藏都会引起窗口的高度发生变化
            const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
            if (resizeHeight < originalHeight) {
                //当软键盘弹起，在此处操作
                setShowKeyboard(true);
                setShowTip(false);
            } else {
                //当软键盘收起，在此处操作
                setShowKeyboard(false);
            }
        });
        // 2. IOS系统(兼容)
        document.body.addEventListener('focusin', () => {
            //软键盘弹出的事件处理
            setShowKeyboard(true);
            setShowTip(false);
        });
        document.body.addEventListener('focusout', () => {
            //软键盘收起的事件处理
            setShowKeyboard(false);
        });


        // if (fromImage === true) {
        //     setQuantity([
        //         {
        //             title: "1",
        //             select: true
        //         }
        //     ])
        // }else{
            setQuantity([
                {
                    title: "1",
                    select: false
                },
                {
                    title: "2",
                    select: false
                },
                {
                    title: "3",
                    select: true
                }
            ])
        // }


    }, [])

    /*
    * color:#FFFFFF;
   caret-color:#FFFFFF;
    * */

    return <Content style={{paddingBottom: (showKeyboard === true && showConfig === false) ? "0" : "env(safe-area-inset-bottom) ",backgroundColor:theme === "light" ? "#E8E8E8" : "#000000"}}>
        <FieldBg style={{backgroundColor: theme === "light" ? "#D8D8D8" : "#292929FF"}}>
            <Field style={{color: theme === "light" ? "#000000" : "#FFFFFF",caretColor: theme === "light" ? "#000000" : "#FFFFFF"}} type="text" placeholder={getLanguageStr(lang,"Generate picture description…","请输入生成图片描述…","生成したい画像を描写してください")} value={text} onChange={(e) => {
                setText(e.target.value);
            }}/>
            {
                (text.length === 0 || canSend === false) ? <SendBtn src={Icon_unSend}/> :
                    <SendBtn src={Icon_send} onClick={() => {

                        if (submit !== undefined) {
                            submit({
                                text: text,
                                samples: samples,
                                style: style,
                                width: width,
                                imageStrength: imageStrength,
                                height: height,
                                imageMode: imageMode,
                            });
                        }

                        setText("")
                    }}/>
            }
        </FieldBg>
        <ConfigBtn src={theme === "light" ? Icon_config_light : Icon_config} onClick={() => setShowConfig(true)}/>
        <TipView imgUrl={Icon_tip_bg} style={{opacity: showTip ? 1 : 0}}>
            <RowWrapper>
                <TipText>
                    {
                        getLanguageStr(lang," Please describe the characteristics of the image you want to generate, such as color, style, size, etc.","请描述您想要生成的图像的特征，如颜色、风格、大小等。","生成したい画像の特徴を説明してください。色、スタイル、サイズなどです。")
                    }

                </TipText>
                <Close src={Icon_tip_close} onClick={() => {
                    setShowTip(false);
                }}/>
            </RowWrapper>
        </TipView>
        <ConfigView style={{top: showConfig ? "-350px " : "calc(50px + env(safe-area-inset-bottom))"}}>
            <RowWrapper style={{justifyContent: 'space-between'}}>
                <CloseBtn src={Icon_close} style={{opacity: 0}}/>
                <Title>{getLanguageStr(lang,"Config","配置参数","設定")}</Title>
                <CloseBtn src={Icon_close} onClick={() => setShowConfig(false)}/>
            </RowWrapper>

            {
                fromImage === false && <>
                    <SubTitle style={{marginTop: '15px'}}>{getLanguageStr(lang,"Height","高度","縦")}: ({height}px)</SubTitle>
                    <RowWrapper style={{marginLeft: '25px', marginRight: '25px', marginTop: '10px'}}>
                        <Slider min={512}
                                max={1024}
                                step={64}
                                // marks={{
                                //     320:' ',
                                //     384:' ',
                                //     448:' ',
                                //     512:' ',
                                //     576:' ',
                                //     640:' ',
                                //     704:' ',
                                //     768:' ',
                                //     832:' ',
                                //     896:' ',
                                //     960:' ',
                                //     1024:' ',
                                // }}
                                defaultValue={512}
                                onChange={(value) => {
                                    setHeight(value)
                                }}/>
                    </RowWrapper>

                    <SubTitle style={{marginTop: '15px'}}>{getLanguageStr(lang,"Width","宽度","横")}: ({width}px)</SubTitle>
                    <RowWrapper style={{marginLeft: '25px', marginRight: '25px', marginTop: '10px'}}>
                        <Slider min={512}
                                max={1024}
                                step={64}
                                // marks={{
                                //     192:' ',
                                //     256:' ',
                                //     320:' ',
                                //     384:' ',
                                //     448:' ',
                                //     512:' ',
                                //     576:' '
                                // }}
                                defaultValue={512}
                                onChange={(value) => {
                                    setWidth(value)
                                }}/>
                    </RowWrapper>
                </>
            }

            {
                fromImage === true && <>

                    <SubTitle style={{marginTop: '15px'}}>{getLanguageStr(lang,"Image Strength","影响度","影響度")}: ({imageStrength * 100}%)</SubTitle>
                    <RowWrapper style={{marginLeft: '25px', marginRight: '25px', marginTop: '10px'}}>
                        <Slider min={0}
                                max={1}
                                step={0.05}
                            // marks={{
                            //     192:' ',
                            //     256:' ',
                            //     320:' ',
                            //     384:' ',
                            //     448:' ',
                            //     512:' ',
                            //     576:' '
                            // }}
                                defaultValue={0.35}
                                onChange={(value) => {
                                    setImageStrength(value)
                                }}/>
                    </RowWrapper>


                    <SubTitle style={{marginTop: '15px'}}>{getLanguageStr(lang,"Mode","模式","モード")}:</SubTitle>
                    <RowWrapper
                        style={{marginTop: "15px", flexWrap: "nowrap", overflow: "scroll", paddingBottom: "5px"}}>
                        {
                            imageModes.map((item, index) => {
                                return <BigCell key={index + 100}
                                                style={{background: item.select ? "linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%)" : "#3F3F3FFF"}}
                                                onClick={() => {
                                                    imageModes.forEach((item, subIndex) => {
                                                        item.select = subIndex === index;

                                                        if (subIndex === index) {
                                                            setImageMode(item.value)
                                                        }
                                                    })
                                                    let newModes = [].concat(imageModes);
                                                    setImageModes(newModes)
                                                }}>
                                    <CellTitle>{item.title}</CellTitle>
                                </BigCell>
                            })
                        }
                    </RowWrapper>
                </>
            }

            <SubTitle style={{marginTop: '15px'}}>{getLanguageStr(lang,"Style","样式","モデル")}:</SubTitle>
            <RowWrapper style={{marginTop: "15px", flexWrap: "nowrap", overflow: "scroll", paddingBottom: "5px"}}>
                {
                    styles.map((item, index) => {
                        return <BigCell key={index + 100}
                                        style={{background: item.select ? "linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%)" : "#3F3F3FFF"}}
                                        onClick={() => {
                                            styles.forEach((item, subIndex) => {
                                                item.select = subIndex === index;

                                                if (subIndex === index) {
                                                    setStyle(item.value)
                                                }
                                            })
                                            let newStyles = [].concat(styles);
                                            setStyles(newStyles)
                                        }}>
                            <CellTitle>{item.title}</CellTitle>
                        </BigCell>
                    })
                }
            </RowWrapper>
            <SubTitle style={{marginTop: "15px"}}>{getLanguageStr(lang,"Quantity","数量","生成枚数")}:</SubTitle>
            <RowWrapper style={{marginTop: "15px"}}>
                {
                    quantity.map((item, index) => {
                        return <SmallCell key={index}
                                          style={{background: item.select ? "linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%)" : "#3F3F3FFF"}}
                                          onClick={() => {

                                              quantity.forEach((item, subIndex) => {
                                                  item.select = subIndex === index;

                                                  if (subIndex === index) {
                                                      setSamples(index + 1)
                                                  }

                                              })
                                              let newQuantity = [].concat(quantity);
                                              setQuantity(newQuantity)

                                          }}>
                            {item.title}
                        </SmallCell>
                    })
                }
            </RowWrapper>
        </ConfigView>
    </Content>
}

export default TabBar

const Content = styled.div`
  width: 100vw;
  background-color:#000000;
  height:50px;
  padding-bottom:env(safe-area-inset-bottom);
 
  position: fixed;
  bottom:0;
 
  display: flex;
  flex-direction: row;
  z-index: 1000;
  
  border-top: 1px solid #FFFFFF33;
`

const FieldBg = styled.div`
   height: 40px;
   width: calc(100vw - 75px);
   background-color:#292929FF;
   margin-left:15px;
   margin-top:5px;
   border-radius: 4px;
   
   display: flex;
   flex-direction: row;

   align-items: center;
`

const Field = styled.input`
  
   flex: 1;
   margin-left: 10px;
   margin-right: 10px;
   height: 40px;
   border:none;
   background-color:transparent;
   font-size: 14px;
   color:#FFFFFF;
   caret-color:#FFFFFF;
   
   &:focus{
     outline:none;
   }
`

const SendBtn = styled.img`
  width: 20px;
  height: 20px;
  padding: 10px;
  
  &:active{
     opacity:0.8;
  }
`

const ConfigBtn = styled.img`
  width: 28px;
  height: 28px;
  padding: 6px;
  margin-top:5px;
  margin-left:5px;
  
  &:active{
     opacity:0.8;
  }
`

const TipView = styled.div`

  position: absolute;
  left: 50px;
  top: -70px;

  width: 277px;
  height: 80px;
 
  background: url(${(props) => props.imgUrl}) no-repeat;
  background-size: 100% 100%;
  
  transition: all 0.3s ease-in-out;
`

const TipText = styled.div`
 
 margin-left: 10px;
 margin-top: 10px;
 
 font-size: 12px;
 font-weight: 400;
 color: #FFFFFF;
 line-height: 17px;
   
`

const Close = styled.img`

  width: 16px;
  height: 16px;
  padding: 10px;
  
  &:active{
     opacity:0.8;
  }
   
`

const CloseBtn = styled.img`

  width: 16px;
  height: 16px;
  padding: 12px;
  
  &:active{
     opacity:0.8;
  }
`

const ConfigView = styled.div`
  position: absolute;

  width: 100vw;
  height: 400px;
  padding-bottom:env(safe-area-inset-bottom);
  
  top: calc(-350px);
  background: #000000D9;

  transition: all 0.3s ease-in-out;
  
  display: flex;
  flex-direction: column;
`

const Title = styled.div`

  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 40px;
  text-align: center;
`

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  
  margin-left:15px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  /*隐藏滚动条*/
  &::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}
`

const BigCell = styled.div`
  width: 90px;
  height: 42px;
  background: #3F3F3F;
  border-radius: 4px;
  
  margin-left:15px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  
  &:active{
    opacity:0.8;
  }
  
`
const CellTitle = styled.div`
  
  width: 90px;

  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 14px;
  text-align: center;
`
const SmallCell = styled.div`
  width: 70px;
  height: 28px;
  background: #3F3F3F;
  border-radius: 4px;
  margin-left:15px;
  
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
  
  &:active{
    opacity:0.8;
  }
`




