import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import MeCell from "../component/MeCell";
import AICell from "../component/AICell";
import TabBar from "../component/TabBar";
import {haveUsedMiniApp, textToImage, getChatStr} from "../../utils/service";
import Icon_pic from "../../asset/icon_pic@2x.png";
import Icon_pic_light from "../../asset/icon_pic_light.png";
import useParsedQueryString from "../../utils/useParsedQueryString";
import {getLanguageStr} from "../../utils/utils";

function TextToImagePage() {

    const location = useParsedQueryString()
    const [data, setData] = useState([])
    const [canSend, setCanSend] = useState(true)

    const [theme, setTheme] = useState("dark")
    const [lang, setLang] = useState("en")

    useEffect(() => {
        document.title = 'Text Generated Images'

        // 设置token
        localStorage.setItem("token", location.token)

        setTheme(location.theme)
        setLang(location.lang)

        // alert(window.location)

        let el = document.getElementById("body_theme")
        if (location.theme === "light") {
            el.style.backgroundColor = "#FFFFFF"
        } else {
            el.style.backgroundColor = "#000000"
        }

        if (data.length === 0) {

            // 生成一条例子提示
            let newData = data.concat([{
                from: "left",
                text: getLanguageStr(location.lang,
                    "Example:\n1. Beautiful waterfall in a lush jungle, with sunlight shining through the trees, serene, peaceful, tropical, jungle landscape, high detail.\n2. A Japanese woman in a kimono is walking through the old streets of Kyoto. Her eyes are mysterious like a deep forest, and her smile is like moonlight that illuminates the darkness.\n                ",
                    "例子:\n1.一位穿着汉服的女性正在老街道上漫步。她的眼睛神秘如深林，她的笑容犹如照亮黑暗的月光。\n2.一个美丽的瀑布位于茂密的丛林中，阳光透过树木照射下来，宁静、平和，带有热带气息的丛林景色，细节丰富。",
                    "例文: \n1.豊かなジャングルにある美しい滝、木々を通して差し込む日光、穏やかで平和な、トロピカルなジャングルの風景、細部まで詳しく。\n２.着物を着た日本人の女性、京都の古い街を歩いています。彼女の目は深い森のように神秘的で、彼女の微笑みはその暗闇を照らす月明かりのようでした。"),
                samples: 0,
                pics: []
            }])

            setData(newData)
        }

    }, [location])

    function userMiniAPP2() {

        localStorage.setItem("token", location.token)
        haveUsedMiniApp({miniId: "g04a3317935ca3d6c01"}).then((response) => {
            if (response.data.code === 200) {
                console.log("积分消耗成功")
            } else {
                console.log("积分消耗失败")
            }
        })
    }

    function dealError(item, newData) {
        let newPics = [].concat([])

        for (let index = 0; index < item.samples; index++) {
            newPics.push("error")
        }

        newData[newData.length - 1].pics = newPics

        let newData2 = [].concat(newData);
        console.log("newData2:", newData2)
        setData(newData2)
        setCanSend(true)
    }

    function dealTextToImage(item) {

        /*生成一条请求记录*/

        let newData = data.concat([{
            from: "left",
            text: item.text,
            samples: item.samples,
            pics: []
        }, {
            from: "right",
            text: item.text,
            samples: item.samples,
            pics: []
        }])

        setData(newData)

        setTimeout(() => {
            let elem = document.getElementById("contentID")
            elem.scrollIntoView({behavior: "smooth", block: "end"})
        }, 300)

        setCanSend(false)
        item.tran = item.text

        getChatStr(
            {
                model: "gpt-3.5-turbo",
                messages: [{"role": "user", "content": "请将冒号后面的内容翻译成英文并且只返回翻译的内容:" + item.text}]
            }
        ).then((response) => {

            let choices = response.data.choices
            if (choices.length > 0) {
                let choice = choices[0]
                item.tran = choice.message.content
                textToImage(
                    JSON.stringify({
                        height: item.height,
                        width: item.width,
                        text_prompts: [{text: item.tran}],
                        samples: item.samples,
                        style_preset: item.style
                    })
                ).then((response) => {
                    const artifacts = response.data.artifacts

                    console.log(artifacts)

                    if (artifacts.length > 0) {

                        console.log(artifacts.length)

                        let newPics = [].concat([])

                        artifacts.forEach(((obj) => {
                            let base64 = "data:image/png;base64," + obj.base64
                            newPics.push(base64)
                        }))

                        newData[newData.length - 1].pics = newPics
                        newData[newData.length - 1].tran = item.tran

                        let newData2 = [].concat(newData);
                        setData(newData2)
                        setCanSend(true)

                        // console.log("newData2:",newData2)

                        userMiniAPP2()

                    } else {
                        // alert("There seems to have been a mistake！")
                        dealError(item, newData)
                    }
                }).catch(e => {
                    // alert("There seems to have been a mistake！")
                    dealError(item, newData)
                })

            } else {
                dealError(item, newData)
            }
        }).catch(e => {
            dealError(item, newData)
        })
    }

    return <>
        <TabBar fromImage={false} canSend={canSend} theme={theme} lang={lang} submit={dealTextToImage}/>
        {
            data.length > 0 && <Content id={"contentID"}>
                {
                    data.map((item, index) => {
                        if (item.from === "left") {
                            return <MeCell theme={theme} lang={lang} key={index} text={item.text}/>
                        } else {
                            console.log("item:", item)
                            return <AICell theme={theme} lang={lang} key={index} text={item.tran} pics={item.pics}
                                           samples={item.samples}/>
                        }
                    })
                }
            </Content>
        }

        {/*{*/}
        {/*    data.length === 0 && <Content2 id={"contentID"}>*/}
        {/*        <Logo src={theme === "light" ? Icon_pic_light : Icon_pic}/>*/}
        {/*        <Title*/}
        {/*            style={{color: theme === "light" ? "#000000" : "#FFFFFF"}}>{getLanguageStr(lang, "Experience generating images from AI text descriptions right away.", "立即体验通过文字描述生成图片。", "文章による画像生成をすぐに体験できます。")}</Title>*/}
        {/*    </Content2>*/}
        {/*}*/}
    </>
}

export default TextToImagePage

const Content = styled.div`
  padding-bottom:calc(env(safe-area-inset-bottom) + 50px) !important;
`

const Content2 = styled.div`
  height:calc(100vh - 250px);
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
 width: 80px;
 height: 80px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  text-align: center;
  width: 280px;
  margin-top:10px;

`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

