import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import MeCell from "../component/MeCell";
import AICell from "../component/AICell";
import TabBar from "../component/TabBar";
import InfoHeader from "../component/InfoHeader";
import {imageToImage, haveUsedMiniApp, getChatStr} from "../../utils/service";
import useParsedQueryString from "../../utils/useParsedQueryString";
import Icon_pic from "../../asset/icon_pic@2x.png";
import Icon_pic_light from "../../asset/icon_pic_light.png";

import lrz from "lrz"
import {getLanguageStr} from "../../utils/utils";

function ImageToImagePage() {

    const location = useParsedQueryString()
    const [data, setData] = useState([])
    const [pic, setPic] = useState(undefined)
    const [newBlob, setNewBlob] = useState(undefined)
    const [canSend, setCanSend] = useState(false)

    const [theme, setTheme] = useState("dark")
    const [lang, setLang] = useState("en")

    useEffect(() => {

        document.title = 'Image Generated Images'
        // 设置token
        localStorage.setItem("token", location.token)

        setTheme(location.theme)
        setLang(location.lang)

        // alert(window.location)

        let el = document.getElementById("body_theme")
        if (location.theme === "light") {
            el.style.backgroundColor = "#FFFFFF"
        } else {
            el.style.backgroundColor = "#000000"
        }

        if (data.length === 0) {

            // 生成一条例子提示
            let newData = data.concat([{
                from: "left",
                text: getLanguageStr(location.lang,
                    "Example:\n1.Upload a landscape photo and enter \"Greek goddess\", and the goddess will appear in your landscape!\n2.Upload a face photo and enter \"Anime style, long legs, big eyes, long hair\", and you'll beautifully transform!",
                    "例子:\n1.上传一张风景照，输入\"希腊女神\"，女神就会在你的风景里啦！\n2.上传一张人物的大头照，输入\"动漫风格，长腿，大眼睛，长头发 “，你就可以为所欲为啦！",
                    "例文: \n1.風景写真をアップして、\"ギリシャの女神\"と入力すると、女神はあなたの風景の中に現れるよ！\n2.顔写真をアップして、\"アニメスタイル、長い脚、大きな目、長い髪\"と入力すれば、きれいに変身するよ！"),
                samples: 0,
                pics: []
            }])

            setData(newData)
        }

    }, [location])

    function userMiniAPP() {

        localStorage.setItem("token", location.token)
        haveUsedMiniApp({miniId: "g180b1163963a38578f"}).then((response) => {
            if (response.data.code === 200) {
                console.log("积分消耗成功")
            } else {
                console.log("积分消耗失败")
            }
        })

    }

    function dealError(item, newData) {
        let newPics = [].concat([])

        for (let index = 0; index < item.samples; index++) {
            newPics.push("error")
        }

        newData[newData.length - 1].pics = newPics

        let newData2 = [].concat(newData);
        console.log("newData2:", newData2)
        setData(newData2)
        setCanSend(true)
    }

    function dealImageToImage(item) {

        /*生成一条请求记录*/
        let newData = data.concat([{
            from: "left",
            text: item.text,
            samples: item.samples,
            pics: []
        }, {
            from: "right",
            text: item.text,
            samples: item.samples,
            pics: []
        }])

        setData(newData)

        setTimeout(() => {
            let elem = document.getElementById("contentID")
            elem.scrollIntoView({behavior: "smooth", block: "end"})
        }, 300)

        // let arr = pic.split(",")
        // let init_image = ""
        // if (arr.length === 2) {
        //     init_image = arr[1]
        //     // console.log(arr[1])
        // }
        //
        // init_image = pic

        setCanSend(false)
        item.tran = item.text


        getChatStr(
            {
                model: "gpt-3.5-turbo",
                messages: [{"role": "user", "content": "请将冒号后面的内容翻译成英文并且只返回翻译的内容:" + item.text}]
            }
        ).then((response) => {

            let choices = response.data.choices
            if (choices.length > 0) {


                let choice = choices[0]
                item.tran = choice.message.content

                const formData = new FormData()
                // let bytes = window.atob(init_image.split(',')[1]);
                // let bytes = window.atob(newBlob);
                // alert("bytes = " + bytes)

                // let array = [];
                // for (let i = 0; i < bytes.length; i++) {
                //     array.push(bytes.charCodeAt(i));
                // }
                // let blob = new Blob([new Uint8Array(array)], {type: 'image/png'});

                // console.log("blob = ", blob)


                console.log("pic = ", pic)
                console.log("newBlob = ", newBlob)

                formData.append('init_image', newBlob)
                formData.append('init_image_mode', item.imageMode)
                formData.append('text_prompts[0][text]', item.tran)
                formData.append('text_prompts[0][weight]', 0.5)
                formData.append('cfg_scale', 7)
                formData.append('samples', item.samples)
                formData.append('steps', 50)
                formData.append('style_preset', item.style)
                console.log("formData = " + formData.toString())

                if (item.imageMode !== 'IMAGE_STRENGTH') {
                    formData.append('step_schedule_start', 0.65)
                    formData.append('step_schedule_end', 0.9)
                } else {
                    formData.append('image_strength', item.imageStrength)
                }

                imageToImage(
                    formData
                ).then((response) => {
                    const artifacts = response.data.artifacts
                    if (artifacts.length > 0) {

                        let newPics = [].concat([])

                        artifacts.forEach(((obj) => {
                            let base64 = "data:image/png;base64," + obj.base64
                            // setPic(base64)
                            newPics.push(base64)
                        }))

                        newData[newData.length - 1].pics = newPics
                        newData[newData.length - 1].tran = item.tran

                        let newData2 = [].concat(newData);
                        setData(newData2)
                        setCanSend(true)

                        // console.log("newData2:", newData2)

                        userMiniAPP()

                    } else {
                        // alert("There seems to have been a mistake！")
                        dealError(item, newData)
                    }
                }).catch(e => {
                    // alert("There seems to have been a mistake！")
                    dealError(item, newData)
                })

            } else {
                dealError(item, newData)
            }
        }).catch(e => {
            dealError(item, newData)
        })
    }

    return <>
        <TabBar fromImage={true} canSend={canSend} theme={theme} lang={lang} submit={dealImageToImage}/>
        {/*{*/}
        {/*    pic !== undefined &&*/}
        {/*    <>*/}
        <InfoHeader lang={lang} pic={pic} submit={(url, blob) => {
            // lrz(e).then(function (rst) {
            //     // 处理成功会执行
            //     // console.log(rst.base64);
            //
            // })

            setPic(url)
            setNewBlob(blob)
            setCanSend(true)
        }}/>
        <Content lang={lang} id={"contentID"}>
            {
                data.map((item, index) => {
                    if (item.from === "left") {
                        return <MeCell theme={theme} lang={lang} key={index} text={item.text}/>
                    } else {
                        console.log("item:", item)
                        return <AICell theme={theme} lang={lang} key={index} text={item.tran} pics={item.pics}
                                       samples={item.samples}/>
                    }
                })
            }
        </Content>
        {/*    </>*/}
        {/*}*/}

        {/*{*/}
        {/*    pic === undefined && <Content2 id={"contentID"}>*/}
        {/*        <Logo src={theme === "light" ? Icon_pic_light : Icon_pic}/>*/}
        {/*        <Title*/}
        {/*            style={{color: theme === "light" ? "#000000" : "#FFFFFF"}}>{getLanguageStr(lang, "Please upload a picture for image generation.", "请上传一张图片用于AI生成图片参考。", "AI画像生成の参考に画像を1枚アップロードしていただきます。")}</Title>*/}
        {/*        <Button onClick={() => {*/}
        {/*            let input = document.createElement('input')*/}
        {/*            input.setAttribute('type', 'file')*/}
        {/*            input.setAttribute('accept', 'image/*')*/}
        {/*            // input.setAttribute('capture', 'album')*/}

        {/*            // 调用相机必须添加实体dom*/}
        {/*            input.style.display = "none"*/}
        {/*            document.body.appendChild(input)*/}

        {/*            input.click()*/}

        {/*            input.onchange = function (e) {*/}

        {/*                lrz(this.files[0]).then(function (rst) {*/}
        {/*                    // 处理成功会执行*/}
        {/*                    // console.log(rst.base64);*/}
        {/*                    setPic(rst.base64)*/}
        {/*                    setCanSend(true)*/}
        {/*                })*/}

        {/*                // let reader = new FileReader();*/}
        {/*                // reader.readAsDataURL(this.files[0]);*/}
        {/*                // reader.onload = function(e){*/}
        {/*                //     setPic(e.target.result)*/}
        {/*                // };*/}
        {/*            }*/}
        {/*        }}>{getLanguageStr(lang, "Upload a picture", "上传图片", "あっぷろーど")}</Button>*/}
        {/*    </Content2>*/}
        {/*}*/}
    </>
}

export default ImageToImagePage

const Content = styled.div`
  padding-top: 101px;
  padding-bottom:calc(env(safe-area-inset-bottom) + 50px) !important;
`

const Content2 = styled.div`
  height:calc(100vh - 250px);
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
 width: 80px;
 height: 80px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  text-align: center;
  width: 280px;
  margin-top:10px;
`

const Button = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  margin-top:30px;
  line-height: 36px;
  
  width: 180px;
  height: 36px;
  background: linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%);
  border-radius: 4px;
  
  &:active{
    opacity:0.8;
  }
`


const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

