import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom'
import ImageToImagePage from "./Pages/imageToImagePage";
import TextToImagePage from "./Pages/textToImagePage";
import BringingOldPhotos from "./Pages/bringingOldPhotos";
import BlindFaceRestoration from "./Pages/blindFaceRestoration";
import Colorization from "./Pages/colorization";
import PracticalFaceRestoration from "./Pages/practicalFaceRestoration";
import Animate from "./Pages/animate";
import Audio from "./Pages/audio";
import Scribble from "./Pages/scribble";
import Music from "./Pages/music";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Route exact strict path="/" component={TextToImagePage}/>
      <Route exact strict path="/textgenerateimage" component={TextToImagePage}/>
      <Route exact strict path="/imagegenerateimage" component={ImageToImagePage}/>
      <Route exact strict path="/bringingoldphotos" component={BringingOldPhotos}/>
      <Route exact strict path="/blindfacerestoration" component={BlindFaceRestoration}/>
      <Route exact strict path="/colorization" component={Colorization}/>
      <Route exact strict path="/practicalfacerestoration" component={PracticalFaceRestoration}/>
      <Route exact strict path="/animate" component={Animate}/>
      <Route exact strict path="/audio" component={Audio}/>
      <Route exact strict path="/music" component={Music}/>
      <Route exact strict path="/scribble" component={Scribble}/>
    </BrowserRouter>
  </React.StrictMode>,
    document.getElementById('root')
);
