import React, {useEffect} from 'react'
import styled from 'styled-components'
import Icon_Me from '../../asset/icon_me@2x.png'

function MeCell({text,theme}) {

    useEffect(() => {

    }, [])

    return <Content style={{backgroundColor: theme === "light" ? "#E8E8E8" : "#2D2D2D" }}>
        <Icon src={Icon_Me}/>
        <Text style={{color: theme === "light" ? "#000000" : "#FFFFFF" }}>{text}</Text>
    </Content>
}

export default MeCell

const Content = styled.div`
  
  display: flex;
 
  background-color:#2D2D2D;
  min-height: 45px;
  margin-bottom:10px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Text = styled.div`
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
  margin-left:10px;
  margin-right:15px;
  margin-top:12px;
  margin-bottom:12px;
  font-weight: 400;
  white-space: pre-wrap;
`
const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-left:15px;
  margin-top:7px;
  background-color:transparent;
  
`

