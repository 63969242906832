import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Icon_unSend from '../../asset/icon_unsend@2x.png'
import Icon_close from '../../asset/icon_close@2x.png'
import Icon_tip_bg from '../../asset/icon_tip_bg@2x.png'
import Icon_tip_close from '../../asset/icon_tip_close@2x.png'
import Icon_send from '../../asset/icon_send@2x.png'
import Icon_config from '../../asset/icon_config@2x.png'
import Icon_config_light from '../../asset/icon_config@2x_light.png'
import Slider from 'rc-slider';
import './slider.css';
import {getLanguageStr} from "../../utils/utils";
import {Button} from "antd-mobile";

function ReplicateTabBar({canSend, submit, theme,lang}) {

    const [showKeyboard, setShowKeyboard] = useState(false)
    const [showTip, setShowTip] = useState(true)
    const [showConfig, setShowConfig] = useState(false)

    useEffect(() => {

        // 1. Android系统
        const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
        window.addEventListener('resize', () => {
            //键盘弹起与隐藏都会引起窗口的高度发生变化
            const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
            if (resizeHeight < originalHeight) {
                //当软键盘弹起，在此处操作
                setShowKeyboard(true);
                setShowTip(false);
            } else {
                //当软键盘收起，在此处操作
                setShowKeyboard(false);
            }
        });
        // 2. IOS系统(兼容)
        document.body.addEventListener('focusin', () => {
            //软键盘弹出的事件处理
            setShowKeyboard(true);
            setShowTip(false);
        });
        document.body.addEventListener('focusout', () => {
            //软键盘收起的事件处理
            setShowKeyboard(false);
        });

    }, [])



    return <Content style={{paddingBottom: (showKeyboard === true && showConfig === false) ? "0" : "env(safe-area-inset-bottom) ",backgroundColor:theme === "light" ? "#E8E8E8" : "#000000"}}>
        <FieldBg style={{backgroundColor: theme === "light" ? "#D8D8D8" : "#292929FF"}}>
            <Button disabled={!canSend} onClick={submit} style={{color: '#aaa', fontSize: 16, width: '100%', backgroundColor: theme === "light" ? "#D8D8D8" : "#292929FF", border: 'none'}}>确认</Button>
        </FieldBg>
    </Content>
}

export default ReplicateTabBar

const Content = styled.div`
  width: 100vw;
  background-color:#000000;
  height:50px;
  padding-bottom:env(safe-area-inset-bottom);

  position: fixed;
  bottom:0;

  display: flex;
  flex-direction: row;
  z-index: 1000;

  border-top: 1px solid #FFFFFF33;
`

const FieldBg = styled.div`
   height: 40px;
   width: 100vw;
   background-color:#292929FF;
   margin-left:15px;
   margin-right: 15px;
   margin-top:5px;
   border-radius: 4px;

   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
`

const Field = styled.input`

   flex: 1;
   margin-left: 10px;
   margin-right: 10px;
   height: 40px;
   border:none;
   background-color:transparent;
   font-size: 14px;
   color:#FFFFFF;
   caret-color:#FFFFFF;

   &:focus{
     outline:none;
   }
`

const SendBtn = styled.img`
  width: 20px;
  height: 20px;
  padding: 10px;

  &:active{
     opacity:0.8;
  }
`

const ConfigBtn = styled.img`
  width: 28px;
  height: 28px;
  padding: 6px;
  margin-top:5px;
  margin-left:5px;

  &:active{
     opacity:0.8;
  }
`

const TipView = styled.div`

  position: absolute;
  left: 50px;
  top: -70px;

  width: 277px;
  height: 80px;

  background: url(${(props) => props.imgUrl}) no-repeat;
  background-size: 100% 100%;

  transition: all 0.3s ease-in-out;
`

const TipText = styled.div`

 margin-left: 10px;
 margin-top: 10px;

 font-size: 12px;
 font-weight: 400;
 color: #FFFFFF;
 line-height: 17px;

`

const Close = styled.img`

  width: 16px;
  height: 16px;
  padding: 10px;

  &:active{
     opacity:0.8;
  }

`

const CloseBtn = styled.img`

  width: 16px;
  height: 16px;
  padding: 12px;

  &:active{
     opacity:0.8;
  }
`

const ConfigView = styled.div`
  position: absolute;

  width: 100vw;
  height: 400px;
  padding-bottom:env(safe-area-inset-bottom);

  top: calc(-350px);
  background: #000000D9;

  transition: all 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
`

const Title = styled.div`

  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 40px;
  text-align: center;
`

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;

  margin-left:15px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  /*隐藏滚动条*/
  &::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}
`

const BigCell = styled.div`
  width: 90px;
  height: 42px;
  background: #3F3F3F;
  border-radius: 4px;

  margin-left:15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;

  &:active{
    opacity:0.8;
  }

`
const CellTitle = styled.div`

  width: 90px;

  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 14px;
  text-align: center;
`
const SmallCell = styled.div`
  width: 70px;
  height: 28px;
  background: #3F3F3F;
  border-radius: 4px;
  margin-left:15px;

  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;

  &:active{
    opacity:0.8;
  }
`




