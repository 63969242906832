import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import Pic2 from '../../asset/pics2.jpeg'
import Icon_pic from "../../asset/pic_zw.png";
import {getLanguageStr} from "../../utils/utils";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import request from "../../utils/request";


let height = 0;
let width = 0;
let x = 0;
let y = 0;

function InfoHeader({pic, submit, lang}) {

    const [image, setImage] = useState(undefined)
    const [fileUrl, setFileUrl] = useState('')
    const [show, setShow] = useState(false)
    const imgRef = useRef(null)


    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        x: 0,
        y: 0,
        width: width,
        height: height,
        locked: true,
        aspect: 1
    })

    useEffect(() => {
        document.title = 'Image Generated Images'
        setImage(pic)
    }, [pic])


    function onComplete(crop) {
        // alert('crop1 = ')
        // console.log('crop1')
    }

    function onChange(crop) {

        // alert('crop2 = ')
        // console.log('crop2')

        setCrop({
            unit: 'px', // Can be 'px' or '%'
            x: crop.x,
            y: crop.y,
            width: width,
            height: height,
            locked: true,
            aspect: 1
        })
    }

    function makeClientCrop() {
        if (imgRef !== null && crop.width && crop.height) {
            getCroppedImg(
                imgRef.current,
                crop,
                "showImage.png"
            ).then((croppedImageUrl) => {
                // alert('croppedImageUrl = ' + croppedImageUrl)
                console.log(croppedImageUrl)
            })
        }
    }

    function getCroppedImg(imageRef, crop, fileName) {

        const canvas = document.createElement("canvas");

        let scale = imageRef.naturalWidth / imageRef.width;

        console.log("imageRef.naturalWidth = " + imageRef.naturalWidth)
        console.log("imageRef.naturalHeight = " + imageRef.naturalHeight)
        console.log("imageRef.width = " + imageRef.width)
        console.log("imageRef.height = " + imageRef.height)
        console.log("scale = " + scale)

        console.log("crop.x = " + crop.x)
        console.log("crop.y = " + crop.y)
        console.log("crop.width = " + crop.width)
        console.log("crop.height = " + crop.height)

        console.log("x = " + crop.x * scale)
        console.log("y = " + crop.y * scale)
        console.log("width = " + crop.width * scale)
        console.log("height = " + crop.height * scale)


        canvas.width = 512;
        canvas.height = 512;

        const ctx = canvas.getContext("2d");

        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.globalAlpha = 1;
        /*s参数为裁剪未知  d参数为作图位置，canvas宽高为图片大小*/
        ctx.drawImage(
            imageRef,
            crop.x * scale,
            crop.y * scale,
            crop.width * scale,
            crop.height * scale,
            0,
            0,
            512,
            512,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                if (fileUrl.length > 0) {
                    window.URL.revokeObjectURL(fileUrl);
                }

                const url = window.URL.createObjectURL(blob);
                setFileUrl(url)
                resolve(url);

                //获取图片
                setImage(url)
                setShow(false)
                if (submit !== undefined) {
                    submit(url, blob)
                }
            }, "image/png");
        });
    }

    return <>
        <Content>
            <Pic src={image === undefined ? Icon_pic : image}/>
            <ColWrapper>
                <Title>{pic === undefined ? getLanguageStr(lang, "Current no reference picture", "当前未上传AI参考图片", "画像がまだUPしていない") : getLanguageStr(lang, "Current reference picture", "当前AI参考图片", "現在のAI参照画像です")}</Title>
                {/*<Alert>{getLanguageStr(lang, "Note: You can only upload images in the text format or images with a height that is a multiple of 64px.", "注意：只能上传文生图的图片，或者图片高为64px倍数的图片。", "注：AI画像生成で作成した画像、または高さ64px倍数の画像データしかできません。\n")}</Alert>*/}
                <Upload onClick={() => {

                    let input = document.createElement('input')
                    input.setAttribute('type', 'file')
                    input.setAttribute('accept', 'image/*')

                    /*调用相机必须添加实体dom*/
                    input.style.display = "none"
                    document.body.appendChild(input)

                    input.click()

                    input.onchange = function () {
                        let reader = new FileReader();
                        reader.readAsDataURL(this.files[0]);

                        console.log(this.files[0]);

                        reader.onload = function (e) {
                            setShow(true)
                            setImage(e.target.result)
                            // if (submit !== undefined) {
                            //     submit(e.target.result)
                            // }
                        };
                    }
                }}>{pic === undefined ? getLanguageStr(lang, "Upload a picture", "上传图片", "アップロード") : getLanguageStr(lang, "Reupload a picture", "重新上传", "再送です")}</Upload>
            </ColWrapper>

        </Content>
        {
            show && <CropMask>
                <ReactCrop
                    style={{maxWidth: '420px'}}
                    aspect={1}
                    crop={crop}
                    onChange={onChange}
                    onComplete={onComplete}
                >
                    <PicTwo
                        ref={imgRef}
                        id={'CropImage112233'}
                        src={image}
                        onLoad={(e) => {

                            let item = document.getElementById('CropImage112233')
                            let bHeight = item.clientHeight

                            if (item.clientHeight > item.clientWidth) {
                                bHeight = item.clientWidth
                            }

                            let count = parseInt(bHeight / 64)
                            bHeight = 64 * count

                            width = bHeight;
                            height = bHeight
                            x = (item.clientWidth - bHeight) / 2.0
                            y = (item.clientHeight - bHeight) / 2.0

                            setCrop({
                                unit: 'px', // Can be 'px' or '%'
                                x: x,
                                y: y,
                                width: width,
                                height: height,
                                locked: true,
                                aspect: 1
                            })

                        }}/>
                </ReactCrop>
                <SaveBtn onClick={() => {
                    makeClientCrop()

                    // setShow(false)

                }}>{getLanguageStr(lang, 'Confirm', '确认裁剪', '確認します')}</SaveBtn>
            </CropMask>
        }
    </>
}


export default InfoHeader


const CropMask = styled.div`
  width: 100vw;
  height: 100vh;
  
  background-color:black;
  
  position: fixed;
  top:0;
  z-index: 1001;
  
  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: center;
`

const SaveBtn = styled.div`
  
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  line-height: 36px;
  
  width: 180px;
  height: 36px;
  background: linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%);
  border-radius: 4px;
  
  position: absolute;
  bottom: 30px;
  left: calc((100vw - 180px) / 2.0);
  
  &:active{
    opacity:0.8;
  }

`

const Content = styled.div`
  width: 100vw;
  background: linear-gradient(225deg, #FFBB6F 0%, #FF256A 35%, #1E50EE 100%);
  height:100px;
  position: fixed;
  top:0;

  display: flex;
  flex-direction: row;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  //justify-content: center;
`

const Pic = styled.img`
  width: 80px;
  min-width: 80px;
  height: 80px;
  margin-left:15px;
  margin-top:10px;
  background-color:black;

  /*填充方式*/
  object-fit: cover;

`

const PicTwo = styled.img`
  width: 100vw;
  background-color:black;
  /*填充方式*/
  object-fit: contain;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 22px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 12px;
`

const Alert = styled.div`
  font-size: 10px;
  color: #FFFFFF;
  line-height: 14px;
  margin-top:3px;
  margin-left: 10px;
  margin-right: 10px;
`

const Upload = styled.div`
  width: 141px;
  height: 24px;
  background: #2050ED;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  border-radius: 4px;

  margin-top: 25px;
  margin-left: 10px;

  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 24px;
  text-align: center;

  &:active{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
  }
`

