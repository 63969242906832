import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import MeCell from "../component/MeCell";
import AnimateAICell from "../component/AnimateAICell";
import AnimateTabBar from "../component/AnimateTabBar";
import {
    haveUsedMiniApp,
    textToImage,
    getChatStr,
    getAnimate,
    bringOldPhotos,
    getReplicatePic
} from "../../utils/service";
import Icon_pic from "../../asset/icon_pic@2x.png";
import Icon_pic_light from "../../asset/icon_pic_light.png";
import useParsedQueryString from "../../utils/useParsedQueryString";
import {getLanguageStr} from "../../utils/utils";

function Animate() {

    const location = useParsedQueryString()
    const [data, setData] = useState([])
    const [canSend, setCanSend] = useState(true)

    const [theme, setTheme] = useState("dark")
    const [lang, setLang] = useState("en")

    const [timer, setTimer] = useState(null);
    const [replicateId, setReplicateId] = useState(null)

    useEffect(() => {
        document.title = 'Animate Your Personalized Text-to-Image Diffusion Models'

        // 设置token
        localStorage.setItem("token", location.token)

        setTheme(location.theme)
        setLang(location.lang)

        // alert(window.location)

        let el = document.getElementById("body_theme")
        if (location.theme === "light") {
            el.style.backgroundColor = "#FFFFFF"
        } else {
            el.style.backgroundColor = "#000000"
        }

    }, [location])

    function userMiniAPP2() {

        localStorage.setItem("token", location.token)
        haveUsedMiniApp({miniId: "g19025c580166f6bbd7"}).then((response) => {
            if (response.data.code === 200) {
                console.log("积分消耗成功")
            } else {
                console.log("积分消耗失败")
            }
        })
    }

    function dealError(item, newData) {
        let newPics = [].concat([])

        for (let index = 0; index < item.samples; index++) {
            newPics.push("error")
        }

        newData[newData.length - 1].pics = newPics

        let newData2 = [].concat(newData);
        console.log("newData2:", newData2)
        setData(newData2)
        setCanSend(true)
    }

    function dealTextToImage(item) {

        /*生成一条请求记录*/

        let newData = data.concat([{
            from: "left",
            text: item.text,
            samples: item.samples,
            pics: []
        }, {
            from: "right",
            text: item.text,
            samples: item.samples,
            pics: []
        }])

        setData(newData)

        setTimeout(() => {
            let elem = document.getElementById("contentID")
            elem.scrollIntoView({behavior: "smooth", block: "end"})
        }, 300)

        setCanSend(false)
        item.tran = item.text

        getChatStr(
            {
                model: "gpt-3.5-turbo",
                messages: [{"role": "user", "content": "请将冒号后面的内容翻译成英文并且只返回翻译的内容:" + item.text}]
            }
        ).then((response) => {

            let choices = response.data.choices
            if (choices.length > 0) {
                let choice = choices[0]
                item.tran = choice.message.content
                console.log(item.tran)
                getAnimate({prompt: choice.message.content, motion_module: item.motionModule, path: item.path, steps: item.steps, guidance_scale: item.guidanceScale}).then( res => {
                    if (res.data.code === 200) {
                        if (res.data.data.status === 'starting') {
                            setReplicateId(res.data.data.id);
                            const timeInterval = setInterval(async () => {
                                const result = await getReplicatePic(res.data.data.id);
                                if (result.data.code === 200) {
                                    if (result.data.data.status === 'processing') {
                                        newData[newData.length - 1].process = 'processing'
                                        newData[newData.length - 1].logs = result.data.data.logs
                                        let newData2 = [].concat(newData);
                                        setData(newData2)
                                    }
                                    if (result.data.data.status === 'failed') {
                                        newData[newData.length - 1].process = 'failed'
                                        newData[newData.length - 1].logs = result.data.data.logs
                                        let newData2 = [].concat(newData);
                                        setData(newData2)
                                        clearInterval(timeInterval);
                                        setTimeout(() => {
                                            let elem = document.getElementById("contentID")
                                            elem.scrollIntoView({behavior: "smooth", block: "end"})
                                        }, 300)
                                    }
                                    if (result.data.data.status === 'succeeded') {
                                        newData[newData.length - 1].pics = [result.data.data.output]
                                        newData[newData.length - 1].tran = item.tran
                                        newData[newData.length - 1].logs = result.data.data.logs
                                        let newData2 = [].concat(newData);
                                        setData(newData2)
                                        setCanSend(true)
                                        clearInterval(timeInterval);
                                        userMiniAPP2()
                                        setTimeout(() => {
                                            let elem = document.getElementById("contentID")
                                            elem.scrollIntoView({behavior: "smooth", block: "end"})
                                        }, 300)
                                    }
                                }
                            }, 3000)
                            setTimer(timeInterval)
                        }
                    }
                })
            } else {

            }
        }).catch(e => {

        })
    }

    return <>
        <AnimateTabBar fromImage={false} canSend={canSend} theme={theme} lang={lang} submit={dealTextToImage}/>
        {
            data.length > 0 && <Content id={"contentID"}>
                {
                    data.map((item, index) => {
                        if (item.from === "left") {
                            return <MeCell theme={theme} lang={lang} key={index} text={item.text}/>
                        } else {
                            return <AnimateAICell theme={theme} lang={lang} key={index} text={item.tran} pics={item.pics}
                                           samples={item.samples} process={item.process} logs={item.logs}/>
                        }
                    })
                }
            </Content>
        }
    </>
}

export default Animate

const Content = styled.div`
  padding-bottom:calc(env(safe-area-inset-bottom) + 50px) !important;
`

const Content2 = styled.div`
  height:calc(100vh - 250px);
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
 width: 80px;
 height: 80px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  text-align: center;
  width: 280px;
  margin-top:10px;

`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

