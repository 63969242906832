import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import Pic2 from '../../asset/pics2.jpeg'
import Icon_pic from "../../asset/pic_zw.png";
import {getLanguageStr} from "../../utils/utils";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import request from "../../utils/request";


let height = 0;
let width = 0;
let x = 0;
let y = 0;

function ReplicateInfoHeader({submit, lang}) {

    const [image, setImage] = useState(undefined)

    return <>
        <Content>
            <Pic src={image === undefined ? Icon_pic : image}/>
            <ColWrapper>
                <Title>{image === undefined ? getLanguageStr(lang, "Current no reference picture", "当前未上传AI参考图片", "画像がまだUPしていない") : getLanguageStr(lang, "Current reference picture", "当前AI参考图片", "現在のAI参照画像です")}</Title>
                {/*<Alert>{getLanguageStr(lang, "Note: You can only upload images in the text format or images with a height that is a multiple of 64px.", "注意：只能上传文生图的图片，或者图片高为64px倍数的图片。", "注：AI画像生成で作成した画像、または高さ64px倍数の画像データしかできません。\n")}</Alert>*/}
                <Upload onClick={() => {

                    let input = document.createElement('input')
                    input.setAttribute('type', 'file')
                    input.setAttribute('accept', 'image/*')

                    /*调用相机必须添加实体dom*/
                    input.style.display = "none"
                    document.body.appendChild(input)

                    input.click();

                    input.onchange = function () {
                        let reader = new FileReader();
                        const file = this.files[0];
                        reader.readAsDataURL(file);
                        reader.onload = function (e) {
                            setImage(e.target.result)
                            submit(file, e.target.result);
                        };
                    }
                }}>{image === undefined ? getLanguageStr(lang, "Upload a picture", "上传图片", "アップロード") : getLanguageStr(lang, "Reupload a picture", "重新上传", "再送です")}</Upload>
            </ColWrapper>

        </Content>
    </>
}


export default ReplicateInfoHeader


const CropMask = styled.div`
  width: 100vw;
  height: 100vh;
  
  background-color:black;
  
  position: fixed;
  top:0;
  z-index: 1001;
  
  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: center;
`

const SaveBtn = styled.div`
  
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  line-height: 36px;
  
  width: 180px;
  height: 36px;
  background: linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%);
  border-radius: 4px;
  
  position: absolute;
  bottom: 30px;
  left: calc((100vw - 180px) / 2.0);
  
  &:active{
    opacity:0.8;
  }

`

const Content = styled.div`
  width: 100vw;
  background: linear-gradient(225deg, #FFBB6F 0%, #FF256A 35%, #1E50EE 100%);
  height:100px;
  position: fixed;
  top:0;
  z-index: 9999999;
  display: flex;
  flex-direction: row;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  //justify-content: center;
`

const Pic = styled.img`
  width: 80px;
  min-width: 80px;
  height: 80px;
  margin-left:15px;
  margin-top:10px;
  background-color:black;

  /*填充方式*/
  object-fit: cover;

`

const PicTwo = styled.img`
  width: 100vw;
  background-color:black;
  /*填充方式*/
  object-fit: contain;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 22px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 12px;
`

const Alert = styled.div`
  font-size: 10px;
  color: #FFFFFF;
  line-height: 14px;
  margin-top:3px;
  margin-left: 10px;
  margin-right: 10px;
`

const Upload = styled.div`
  width: 141px;
  height: 24px;
  background: #2050ED;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  border-radius: 4px;

  margin-top: 25px;
  margin-left: 10px;

  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 24px;
  text-align: center;

  &:active{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
  }
`

