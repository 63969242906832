import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Icon_Open from '../../asset/icon_openweb3@2x.png'
import Icon_Loading from '../../asset/icon_loading.png'
import Icon_Loading_light from '../../asset/icon_loading_light.png'
import Icon_DownPic from '../../asset/icon_download2.png'
import Icon_DownPic_light from '../../asset/icon_download2_light.png'
import Icon_Error from '../../asset/icon_error2.png'
import Icon_Error_light from '../../asset/icon_error2_light.png'
import Pics1 from '../../asset/pics1.jpeg'
import {Button, DotLoading, ImageViewer, Modal, TextArea} from 'antd-mobile'

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import {getLanguageStr} from "../../utils/utils";
import useLongPress from "../../utils/useLongPress";


var flag = 0
var image

function AudioAICell({text = '', pics, samples, theme, lang, process = '', logs = ''}) {

    const [visible, setVisible] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)

    useEffect(() => {

    }, [pics])

    const onLongPress = () => {
        // if (window.ReactNativeWebView) {
        //     window.ReactNativeWebView.postMessage(JSON.stringify({
        //         type: 'SAVE_IMAGE',
        //         data: base64
        //     }))
        //     alert(getLanguageStr(lang,"Picture saved successfully","图片保存成功","画像保存成功です"))
        // }else{
        //     alert(getLanguageStr(lang,"Picture saved failure","图片保存失败","画像保存に失敗しました"))
        // }
    };

    const onClick = () => {
        console.log('click is triggered')
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    return <Content>
        <RowWrapper>
            <Icon src={Icon_Open}/>
            {
                pics.length > 0 &&
                <div style={{flex: 1}}>
                    <Text><TextTip
                      style={{color: theme === "light" ? "#000000" : "#FFFFFF"}}>{getLanguageStr(lang, "Keyword", "关键字", "キーワードです")}: &nbsp;</TextTip>{text}
                    </Text>
                    <div style={{textAlign: 'right', marginLeft: 10, marginRight: 15}}>
                        <Button size='mini'
                                onClick={() => {
                                    pics.forEach(item => {
                                        if (window.ReactNativeWebView) {
                                            window.ReactNativeWebView.postMessage(JSON.stringify({
                                                type: 'SAVE_AUDIO',
                                                data: item
                                            }))
                                            // alert(getLanguageStr(lang,"Picture saved successfully","图片保存成功","画像保存成功です"))
                                        }else{
                                            // alert(getLanguageStr(lang,"Picture saved failure","图片保存失败","画像保存に失敗しました"))
                                        }
                                    })
                                }}
                                color='primary'>{getLanguageStr(lang,"Save Audio","保存音频","オーディオの保存")}</Button>
                    </div>
                </div>

            }

            {
                pics.length == 0 &&
                  <div style={{display: 'flex', alignItems: 'center', padding: 10, boxSizing: 'border-box', marginLeft: 10, width: 'calc(100vw - 70px)', background: '#E8E8E835'}}>
                      <span style={{color: '#1677ff', marginRight: 5}}>{process || 'starting'}</span>
                      {
                        text !== 'failed' && <DotLoading color='primary' />
                      }
                      {
                        logs !== '' && <div style={{marginLeft: 50, flex: 1, textAlign: 'right'}}>
                            <Button size='mini' onClick={() => setModalVisible(true)} color='primary'>{getLanguageStr(lang,"View Log","查看日志","ログの表示")}</Button>
                        </div>
                      }
                  </div>
                // <Skeleton width={"calc(100vw - 70px)"} style={{marginLeft: '10px'}}
                //           baseColor={theme === "light" ? "#E8E8E835" : '#FFFFFF15'}
                //           duration={1.5} highlightColor={theme === "light" ? "#E8E8E8A0" : '#FFFFFFA0'} height={36}
                //           inline={true} count={1}/>
            }
        </RowWrapper>

        {
            pics.length > 0 && pics.map((base64, index) => {

                return <RowWrapper key={index}>
                    {
                        base64 !== "error" && <>
                            {/*{...longPressEvent}*/}
                            <Video src={base64} preload={'auto'} autoplay controls loop />
                        </>
                    }

                    {
                        base64 === "error" && <RowWrapper key={index}>
                            <LoadPicView style={{backgroundColor: theme === "light" ? "#E8E8E8" : "#FFFFFF15"}}>
                                <LoadErrorPic src={theme === "light" ? Icon_Error_light : Icon_Error}/>
                                <LoadPicTitle
                                    style={{color: theme === "light" ? "#000000" : "#939393"}}>{getLanguageStr(lang, "Server is busy, please try again later!", "服务器繁忙，请稍后重试！", "サーバーが忙しいので、後で再試行します!")}</LoadPicTitle>
                            </LoadPicView>
                        </RowWrapper>
                    }
                </RowWrapper>
            })
        }

        <Modal
          style={{'--max-width': '90vw', '--min-width': '90vw'}}
          visible={modalVisible}
          content={<TextArea style={{'--font-size': '14px'}} rows={20} value={logs}></TextArea>}
          closeOnAction
          onClose={() => {
              setModalVisible(false)
          }}
          actions={[
              {
                  key: 'confirm',
                  text: getLanguageStr(lang,"I Known","我知道了","わかりました"),
              },
          ]}
        />
    </Content>
}

export default AudioAICell

const Content = styled.div`

  display: flex;
  min-height: 45px;
  flex-direction: column;
  margin-bottom:20px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  overflow: hidden;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TextTip = styled.span`
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
  font-weight: 400;
`

const Text = styled.div`
  font-size: 14px;
  color: #939393;
  line-height: 20px;
  margin-left:10px;
  margin-right:15px;
  margin-top:12px;
  margin-bottom:12px;
  font-weight: 400;
`
const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-left:15px;
  margin-top:7px;
  background-color:transparent;
`
const DownPic = styled.img`
  width: 24px;
  height: 24px;
  padding:10px;
  margin-left: 5px;
  margin-top: 0;
`

const Pic = styled.img`
  width: 75vw;
  max-width: 75vw;
  
  //height: 75vw;
  margin-left:55px;
  margin-top:10px;
  background-color:transparent;
  
  transition: all 0.3s ease-in-out;
  opacity:1;
  
  /*隐藏无SRC时图片白边*/
  &[src=""],&:not([src]){
    opacity:0;
  }
`

const Video = styled.audio`
    width: 75vw;
    max-width: 75vw;
    margin-left:55px;
    margin-top:10px;
    background-color:transparent;
  
    transition: all 0.3s ease-in-out;
    opacity:1;
`

const LoadPicView = styled.div`
  width: 75vw;
  height: 75vw;
  margin-left:55px;
  margin-top:10px;
  background-color:#FFFFFF15;
  
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
`

const LoadPicTitle = styled.div`
  font-size: 12px;
  color:#939393;
  opacity:1;
  margin-top:10px;
  text-align: center;
`

const LoadErrorPic = styled.img`
  width: 50px;
  height: 50px;
`

const LoadPic = styled.img`
  width: 32px;
  height: 32px;
  
  animation: rotateAnimation 1.0s linear infinite;
  @keyframes rotateAnimation{
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
`

const Footer = styled.div`
  
  background-color: transparent;
  width: 100vw;
  height: 80px;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

`

const SaveBtn = styled.div`
  
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  margin-top:30px;
  line-height: 36px;
  
  width: 180px;
  height: 36px;
  background: linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%);
  border-radius: 4px;
  
  &:active{
    opacity:0.8;
  }

`


