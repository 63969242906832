import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Icon_unSend from '../../asset/icon_unsend@2x.png'
import Icon_close from '../../asset/icon_close@2x.png'
import Icon_tip_bg from '../../asset/icon_tip_bg@2x.png'
import Icon_tip_close from '../../asset/icon_tip_close@2x.png'
import Icon_send from '../../asset/icon_send@2x.png'
import Icon_config from '../../asset/icon_config@2x.png'
import Icon_config_light from '../../asset/icon_config@2x_light.png'
import Slider from 'rc-slider';
import './slider.css';
import {getLanguageStr} from "../../utils/utils";

function MusicTabBar({canSend, submit, fromImage,theme,lang}) {

    const [showKeyboard, setShowKeyboard] = useState(false)
    const [showTip, setShowTip] = useState(true)
    const [showConfig, setShowConfig] = useState(false)


    const [text, setText] = useState("")

    const [modelVersion, setModelVersion] = useState('melody')
    const [modelVersions, setModelVersions] = useState([
        {
            title: "melody",
            value: "melody",
            select: true
        },
        {
            title: "large",
            value: "large",
            select: false
        },
        {
            title: "encode-decode",
            value: "encode-decode",
            select: false
        }
    ])

    const [duration, setDuration] = useState(9);

    const [outputFormat, setOutputFormat] = useState('wav');
    const [outputFormats, setOutputFormats] = useState([
        {
            title: "wav",
            value: "wav",
            select: true
        },
        {
            title: "mp3",
            value: "mp3",
            select: false
        }
    ])

    const [continuation, setContinuation] = useState(false);
    const [continuations, setContinuations] = useState([
        {
            title: 'False',
            value: false,
            select: true
        },
        {
            title: "True",
            value: true,
            select: false
        },
    ]);



    const [normalizationStrategy, setNormalizationStrategy] = useState('loudness');

    const [normalizationStrategys, setNormalizationStrategys] = useState([
        {
            title: "loudness",
            value: "loudness",
            select: true
        },
        {
            title: "clip",
            value: "clip",
            select: false
        },
        {
            title: "peak",
            value: "peak",
            select: false
        },
        {
            title: "rms",
            value: "rms",
            select: false
        }
    ])




    useEffect(() => {
        // 1. Android系统
        const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
        window.addEventListener('resize', () => {
            //键盘弹起与隐藏都会引起窗口的高度发生变化
            const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
            if (resizeHeight < originalHeight) {
                //当软键盘弹起，在此处操作
                setShowKeyboard(true);
                setShowTip(false);
            } else {
                //当软键盘收起，在此处操作
                setShowKeyboard(false);
            }
        });
        // 2. IOS系统(兼容)
        document.body.addEventListener('focusin', () => {
            //软键盘弹出的事件处理
            setShowKeyboard(true);
            setShowTip(false);
        });
        document.body.addEventListener('focusout', () => {
            //软键盘收起的事件处理
            setShowKeyboard(false);
        });


    }, [])

    /*
    * color:#FFFFFF;
   caret-color:#FFFFFF;
    * */

    return <Content style={{paddingBottom: (showKeyboard === true && showConfig === false) ? "0" : "env(safe-area-inset-bottom) ",backgroundColor:theme === "light" ? "#E8E8E8" : "#000000"}}>
        <FieldBg style={{backgroundColor: theme === "light" ? "#D8D8D8" : "#292929FF"}}>
            <Field style={{color: theme === "light" ? "#000000" : "#FFFFFF",caretColor: theme === "light" ? "#000000" : "#FFFFFF"}} type="text" placeholder={getLanguageStr(lang,"Generate music description…","请输入生成音乐描述…","生成音楽の説明を入力してください")} value={text} onChange={(e) => {
                setText(e.target.value);
            }}/>
            {
                (text.length === 0 || canSend === false) ? <SendBtn src={Icon_unSend}/> :
                    <SendBtn src={Icon_send} onClick={() => {

                        if (submit !== undefined) {
                            submit({
                                text,
                                modelVersion,
                                duration,
                                outputFormat,
                                continuation,
                                normalizationStrategy
                            });
                        }

                        setText("")
                    }}/>
            }
        </FieldBg>
        <ConfigBtn src={theme === "light" ? Icon_config_light : Icon_config} onClick={() => setShowConfig(true)}/>
        <ConfigView style={{top: showConfig ? "-450px " : "calc(50px + env(safe-area-inset-bottom))"}}>
            <RowWrapper style={{justifyContent: 'space-between'}}>
                <CloseBtn src={Icon_close} style={{opacity: 0}}/>
                <Title>{getLanguageStr(lang,"Config","配置参数","設定")}</Title>
                <CloseBtn src={Icon_close} onClick={() => setShowConfig(false)}/>
            </RowWrapper>

            <SubTitle style={{marginTop: '15px'}}>{getLanguageStr(lang,"duration","duration","duration")}: ({duration})</SubTitle>
            <RowWrapper style={{marginLeft: '25px', marginRight: '25px', marginTop: '10px'}}>
                <Slider min={2}
                        max={30}
                        step={1}
                        defaultValue={8}
                        onChange={(value) => {
                            setDuration(value)
                        }}/>
            </RowWrapper>

            <SubTitle style={{marginTop: '15px'}}>{getLanguageStr(lang,"model_version","model_version","model_version")}:</SubTitle>
            <RowWrapper style={{marginTop: "15px", flexWrap: "nowrap", overflow: "scroll", paddingBottom: "5px"}}>
                {
                    modelVersions.map((item, index) => {
                        return <BigCell key={index + 100}
                                        style={{background: item.select ? "linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%)" : "#3F3F3FFF"}}
                                        onClick={() => {
                                            modelVersions.forEach((item, subIndex) => {
                                                item.select = subIndex === index;

                                                if (subIndex === index) {
                                                    setModelVersion(item.value)
                                                }
                                            })
                                            let newModelVersions = [].concat(modelVersions);
                                            setModelVersions(newModelVersions)
                                        }}>
                            <CellTitle>{item.title}</CellTitle>
                        </BigCell>
                    })
                }
            </RowWrapper>

            <SubTitle style={{marginTop: '15px'}}>{getLanguageStr(lang,"continuation","continuation","continuation")}:</SubTitle>
            <RowWrapper style={{marginTop: "15px", flexWrap: "nowrap", overflow: "scroll", paddingBottom: "5px"}}>
                {
                    continuations.map((item, index) => {
                        return <BigCell key={index + 100}
                                        style={{background: item.select ? "linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%)" : "#3F3F3FFF"}}
                                        onClick={() => {
                                            continuations.forEach((item, subIndex) => {
                                                item.select = subIndex === index;

                                                if (subIndex === index) {
                                                    setContinuation(item.value)
                                                }
                                            })
                                            let newContinuations = [].concat(continuations);
                                            setContinuations(newContinuations)
                                        }}>
                            <CellTitle>{item.title}</CellTitle>
                        </BigCell>
                    })
                }
            </RowWrapper>

            <SubTitle style={{marginTop: '15px'}}>{getLanguageStr(lang,"normalization_strategy","normalization_strategy","normalization_strategy")}:</SubTitle>
            <RowWrapper style={{marginTop: "15px", flexWrap: "nowrap", overflow: "scroll", paddingBottom: "5px"}}>
                {
                    normalizationStrategys.map((item, index) => {
                        return <BigCell key={index + 100}
                                        style={{background: item.select ? "linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%)" : "#3F3F3FFF"}}
                                        onClick={() => {
                                            normalizationStrategys.forEach((item, subIndex) => {
                                                item.select = subIndex === index;

                                                if (subIndex === index) {
                                                    setNormalizationStrategy(item.value)
                                                }
                                            })
                                            let newNormalizationStrategys = [].concat(normalizationStrategys);
                                            setNormalizationStrategys(newNormalizationStrategys)
                                        }}>
                            <CellTitle>{item.title}</CellTitle>
                        </BigCell>
                    })
                }
            </RowWrapper>
            <SubTitle style={{marginTop: '15px'}}>{getLanguageStr(lang,"output_format","output_format","output_format")}:</SubTitle>
            <RowWrapper style={{marginTop: "15px", flexWrap: "nowrap", overflow: "scroll", paddingBottom: "5px"}}>
                {
                    outputFormats.map((item, index) => {
                        return <BigCell key={index + 100}
                                        style={{background: item.select ? "linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%)" : "#3F3F3FFF"}}
                                        onClick={() => {
                                            outputFormats.forEach((item, subIndex) => {
                                                item.select = subIndex === index;

                                                if (subIndex === index) {
                                                    setOutputFormat(item.value)
                                                }
                                            })
                                            let newOutputFormats = [].concat(outputFormats);
                                            setOutputFormats(newOutputFormats)
                                        }}>
                            <CellTitle>{item.title}</CellTitle>
                        </BigCell>
                    })
                }
            </RowWrapper>
            {/*<SubTitle style={{marginTop: "15px"}}>{getLanguageStr(lang,"Quantity","数量","生成枚数")}:</SubTitle>*/}
            {/*<RowWrapper style={{marginTop: "15px"}}>*/}
            {/*    {*/}
            {/*        quantity.map((item, index) => {*/}
            {/*            return <SmallCell key={index}*/}
            {/*                              style={{background: item.select ? "linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%)" : "#3F3F3FFF"}}*/}
            {/*                              onClick={() => {*/}

            {/*                                  quantity.forEach((item, subIndex) => {*/}
            {/*                                      item.select = subIndex === index;*/}

            {/*                                      if (subIndex === index) {*/}
            {/*                                          setSamples(index + 1)*/}
            {/*                                      }*/}

            {/*                                  })*/}
            {/*                                  let newQuantity = [].concat(quantity);*/}
            {/*                                  setQuantity(newQuantity)*/}

            {/*                              }}>*/}
            {/*                {item.title}*/}
            {/*            </SmallCell>*/}
            {/*        })*/}
            {/*    }*/}
            {/*</RowWrapper>*/}
        </ConfigView>
    </Content>
}

export default MusicTabBar

const Content = styled.div`
  width: 100vw;
  background-color:#000000;
  height:50px;
  padding-bottom:env(safe-area-inset-bottom);
 
  position: fixed;
  bottom:0;
 
  display: flex;
  flex-direction: row;
  z-index: 1000;
  
  border-top: 1px solid #FFFFFF33;
`

const FieldBg = styled.div`
   height: 40px;
   width: calc(100vw - 75px);
   background-color:#292929FF;
   margin-left:15px;
   margin-top:5px;
   border-radius: 4px;
   
   display: flex;
   flex-direction: row;

   align-items: center;
`

const Field = styled.input`
  
   flex: 1;
   margin-left: 10px;
   margin-right: 10px;
   height: 40px;
   border:none;
   background-color:transparent;
   font-size: 14px;
   color:#FFFFFF;
   caret-color:#FFFFFF;
   
   &:focus{
     outline:none;
   }
`

const SendBtn = styled.img`
  width: 20px;
  height: 20px;
  padding: 10px;
  
  &:active{
     opacity:0.8;
  }
`

const ConfigBtn = styled.img`
  width: 28px;
  height: 28px;
  padding: 6px;
  margin-top:5px;
  margin-left:5px;
  
  &:active{
     opacity:0.8;
  }
`

const TipView = styled.div`

  position: absolute;
  left: 50px;
  top: -70px;

  width: 277px;
  height: 80px;
 
  background: url(${(props) => props.imgUrl}) no-repeat;
  background-size: 100% 100%;
  
  transition: all 0.3s ease-in-out;
`

const TipText = styled.div`
 
 margin-left: 10px;
 margin-top: 10px;
 
 font-size: 12px;
 font-weight: 400;
 color: #FFFFFF;
 line-height: 17px;
   
`

const Close = styled.img`

  width: 16px;
  height: 16px;
  padding: 10px;
  
  &:active{
     opacity:0.8;
  }
   
`

const CloseBtn = styled.img`

  width: 16px;
  height: 16px;
  padding: 12px;
  
  &:active{
     opacity:0.8;
  }
`

const ConfigView = styled.div`
  position: absolute;

  width: 100vw;
  height: 500px;
  padding-bottom:env(safe-area-inset-bottom);
  
  top: calc(-350px);
  background: #000000D9;

  transition: all 0.3s ease-in-out;
  
  display: flex;
  flex-direction: column;
`

const Title = styled.div`

  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 40px;
  text-align: center;
`

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  
  margin-left:15px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  /*隐藏滚动条*/
  &::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}
`

const BigCell = styled.div`
  width: 90px;
  height: 42px;
  background: #3F3F3F;
  border-radius: 4px;
  
  margin-left:15px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  
  &:active{
    opacity:0.8;
  }
  
`
const CellTitle = styled.div`
  
  width: 90px;
  // padding: 0 10px;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 14px;
  text-align: center;
`
const SmallCell = styled.div`
  width: 70px;
  height: 28px;
  background: #3F3F3F;
  border-radius: 4px;
  margin-left:15px;
  
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
  
  &:active{
    opacity:0.8;
  }
`




