import axios from 'axios'

const request = axios.create({
  baseURL: "",
  timeout: 60000,
  headers: {}
})

// 请求拦截
request.interceptors.request.use(function (config) {

  return config

}, function (error) {

  return Promise.reject(error)

})

export default request
