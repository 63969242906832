import request from '../utils/request'

//文字生成图片
export function textToImage(data) {
    //https://api.stability.ai/v1/generation/stable-diffusion-v1-5/text-to-image
    //https://api.stability.ai/v1/generation/stable-diffusion-xl-beta-v2-2-2/text-to-image
    return request('https://api.stability.ai/v1/generation/stable-diffusion-xl-beta-v2-2-2/text-to-image', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer sk-LJe6MklTH2LOM8RrbHZnxFutMwwxEPg0hw3y5jKnTAzWoL89',
        },
        data
    })
}

//图片生成图片
export function imageToImage(data) {
    //https://api.stability.ai/v1/generation/stable-diffusion-v1-5/image-to-image
    //https://api.stability.ai/v1/generation/stable-diffusion-xl-beta-v2-2-2/image-to-image
    return request('https://api.stability.ai/v1/generation/stable-diffusion-xl-beta-v2-2-2/image-to-image', {
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': 'Bearer sk-LJe6MklTH2LOM8RrbHZnxFutMwwxEPg0hw3y5jKnTAzWoL89',
        },
        data
    })
}

//消耗积分
export function haveUsedMiniApp(data) {
    let token = localStorage.getItem("token")
    // https://api.openweb3.ai/app/point/use
    // https://api.openweb3.ai/app/point/use
    return request('https://api.openweb3.ai/app/point/use', {
        headers: {'Authorization': "bearer " + token},
        method: 'POST',
        data
    })
}

export function uploadReplicatePic(data) {
    return request('https://api.openweb3.ai/app/upload/replicate', {
        method: 'POST',
        data
    })
}

export function bringOldPhotos(image) {
    return request('https://api.openweb3.ai/app/replicate/predictions', {
        method: 'POST',
        data: {
            image,
            version: "c75db81db6cbd809d93cc3b7e7a088a351a3349c9fa02b6d393e35e0d51ba799"
        }
    })
}

export function blindFaceRestoration(image) {
    return request('https://api.openweb3.ai/app/replicate/predictions', {
        method: 'POST',
        data: {
            image,
            version: "ccd53a9a38ebbaa783a1e6318d22fa68c14c3aed66cc3589e53ef07d07f5be1d"
        }
    })
}

export function bigColor(image) {
    return request('https://api.openweb3.ai/app/replicate/predictions', {
        method: 'POST',
        data: {
            image,
            version: "9451bfbf652b21a9bccc741e5c7046540faa5586cfa3aa45abc7dbb46151a4f7"
        }
    })
}

export function practicalFaceRestoration(image) {
    return request('https://api.openweb3.ai/app/replicate/predictions', {
        method: 'POST',
        data: {
            img: true,
            image,
            version: "9283608cc6b7be6b65a8e44983db012355fde4132009bf99d976b2f0896856a3"
        }
    })
}

export function getAnimate(data) {
    return request('https://api.openweb3.ai/app/replicate/animate', {
        method: 'POST',
        data
    })
}

export function getAudio(data) {
    return request('https://api.openweb3.ai/app/replicate/audio', {
        method: 'POST',
        data
    })
}

export function getScribble(data) {
    return request('https://api.openweb3.ai/app/replicate/scribble', {
        method: 'POST',
        data
    })
}


export function getMusic(data) {
    return request('https://api.openweb3.ai/app/replicate/music', {
        method: 'POST',
        data
    })
}




export function getReplicatePic(id) {
    return request(`https://api.openweb3.ai/app/replicate/predictions?id=${id}`)
}


//翻译中文
export function getChatStr(data) {

    return request('https://apihub.openweb3.ai/openai/v1/chat/completions', {
        headers: {'x-api-key': "ow3-ODM4ZDVmY2YtYWFlYi00NjZkLWIyMjMtZTQ5ZDM4ZDlkZGNh"},
        method: 'POST',
        data
    })

}

//get请求方式
export function testGet(params) {
    return request('/user', {
        method: 'GET',
        params
    })
}



