import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Icon_Me from '../../asset/icon_me@2x.png'
import {ImageViewer} from 'antd-mobile'
import {getLanguageStr} from "../../utils/utils";

var image

function ReplicateMeCell({text,theme, lang}) {

    const [visible, setVisible] = useState(false)

    useEffect(() => {
    }, [])

    return <Content style={{backgroundColor: theme === "light" ? "#E8E8E8" : "#2D2D2D" }}>
        <Icon src={Icon_Me}/>
        <Pic src={text} onClick={() => {
            image = text
            // setVisible(true)
        }}/>
        <ImageViewer
          image={image}
          visible={visible}
          onClose={() => {
              setVisible(false)
          }}
          renderFooter={() => {
              return <Footer>
                  <SaveBtn onClick={() => {
                      if (window.ReactNativeWebView) {
                          window.ReactNativeWebView.postMessage(JSON.stringify({
                              type: 'SAVE_IMAGE',
                              data: image
                          }))
                          alert(getLanguageStr(lang,"Picture saved successfully","图片保存成功","画像保存成功です"))
                      }else{
                          alert(getLanguageStr(lang,"Picture saved failure","图片保存失败","画像保存に失敗しました"))
                      }

                  }}>{getLanguageStr(lang,"Save","保存图片","保存します")}</SaveBtn>
              </Footer>
          }}
        />
    </Content>
}

export default ReplicateMeCell

const Content = styled.div`
  
  display: flex;
 
  background-color:#2D2D2D;
  min-height: 45px;
  margin-bottom:10px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Pic = styled.img`
  width: 75vw;
  max-width: 75vw;
  
  //height: 75vw;
  margin-left:10px;
  margin-top:10px;
  margin-bottom: 10px;
  background-color:transparent;
  
  transition: all 0.3s ease-in-out;
  opacity:1;
  
  /*隐藏无SRC时图片白边*/
  &[src=""],&:not([src]){
    opacity:0;
  }
`

const Text = styled.div`
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
  margin-left:10px;
  margin-right:15px;
  margin-top:12px;
  margin-bottom:12px;
  font-weight: 400;
  white-space: pre-wrap;
`
const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-left:15px;
  margin-top:7px;
  background-color:transparent;
  
`

const Footer = styled.div`
  
  background-color: transparent;
  width: 100vw;
  height: 80px;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

`

const SaveBtn = styled.div`
  
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  margin-top:30px;
  line-height: 36px;
  
  width: 180px;
  height: 36px;
  background: linear-gradient(90deg, #FFBB6F 0%, #FF256A 50%, #1E50EE 100%);
  border-radius: 4px;
  
  &:active{
    opacity:0.8;
  }

`

