export function getLanguageStr(lang, en, ch, jp) {
    if (lang === "en") {
        return en
    } else if (lang === "cn") {
        return ch
    } else if (lang === "jp") {
        return jp
    } else {
        return en
    }
}
